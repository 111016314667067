ion-tab-button > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

ion-tab-button > div ion-label {
  font-size: 1.4em;
}

ion-tab-button ion-icon {
  font-size: 2em;
}
