ion-card.welcome-card {
  --background: var(--ion-color-secondary);
  --color: white;
}

ion-card.welcome-card ion-card-subtitle {
  color: var(--ion-color-tertiary);
}

ion-card.welcome-card ion-card-title {
  color: white;
}

ion-card.welcome-card .welcome-card-container {
  height: 35vh;
  overflow: hidden;
}

ion-card.welcome-card .welcome-card-container img {
  --deckgo-lazy-img-width: 100%;
  --deckgo-lazy-img-height: 100%;
  --deckgo-lazy-img-object-fit: cover;
}

@media screen and (min-width: 720px) {
  ion-card.welcome-card .welcome-card-container {
    height: 45vh;
  }
}

@media screen and (min-width: 960px) {
  ion-card.welcome-card .welcome-card-container {
    height: 55vh;
  }
}

@media screen and (min-width: 1140px) {
  ion-card.welcome-card .welcome-card-container {
    height: 65vh;
  }
}

ion-list.welcome-navigation {
  margin-bottom: 32px;
}

ion-list.welcome-navigation div[slot="start"] {
  color: var(--ion-color-medium);
}
